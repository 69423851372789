import React from 'react';
import styled from 'styled-components';

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  svg {
    width: 50px; /* Adjust the icon size as needed */
    height: 50px;
    margin-right: 5px; /* Space between icon and text */
  }

  span {
    font-size: 20px; /* Adjust the font size as needed */
    color: currentColor;
  }
`;

const IconResume = () => (
  <IconContainer>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-file-text">
      <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" />
      <polyline points="14 2 14 8 20 8" />
      <line x1="16" y1="13" x2="8" y2="13" />
      <line x1="16" y1="17" x2="8" y2="17" />
      <polyline points="10 9 9 9 8 9" />
    </svg>
    <span>Resume</span>
  </IconContainer>
);

export default IconResume;
